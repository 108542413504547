import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "semantic-ui-react";
import { CRUD } from "../../../collections";
import { addFlow, fetchFlows, removeFlow } from "../../../actions";
import Layout from "../../../components/layout";
import Container from "../../../common/components/container";
import { Link } from "gatsby";
const Index = () => {
  const dispatch = useDispatch();
  const [flow_name, update_flow_name] = useState("");
  const flows = useSelector((state) => state.flows);
  const [loading, update_loading] = useState(true);

  useEffect(() => {
    dispatch(fetchFlows());
    update_loading(false);
  }, []);

  function handleAddFlow() {
    dispatch(addFlow(flow_name));
    update_flow_name("");
  }

  function handleRemoveFlow(flow) {
    dispatch(removeFlow(flow));
  }

  function tablehandleUpdateEntry(flow) {
    console.log(flow);
  }

  return (
    <Layout>
      <Container>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Flows
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                {/* <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Share
            </button> */}
                <Link
                  to="/guides/new/"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </Link>
              </div>
            </div>

            <CRUD
              header={"Flows"}
              subHeader={"Add New Flow"}
              add={{
                placeholder: "Flow Name",
                value: flow_name,
                onChange: update_flow_name,
                add: handleAddFlow,
                button_text: "Add Flow",
              }}
              table={{
                type: "TableFlow",
                header_array: ["Name", "ID", "Created", "update", "Remove?"],
                content: flows,
                handleRemoveEntry: handleRemoveFlow,
                handleUpdateEntry: tablehandleUpdateEntry,
              }}
            />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Index;
